import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import { format } from 'date-fns';
export default {
  name: 'addEditPeriod',
  props: ['calHdrId'],
  components: { DatePicker },
  data() {
    return {
      parent_value_set_id: null,
      vsetCode: null,
      showValueSetModal: null,
      unsubscribe: null,
      appCritListIndex: null,
      editMode: true,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      calendarForm: {
        calendar_hdr_id: 0,
        calendar_dtl_id: 0,
        calendar_name: null,
        description: null,
        start_date: null,
        end_date: null,
        start_end_date: null,
        financial_year_display: null
      },
      calendarDetailList: [
        {
          adjustment_period_flag: false,
          calendar_line_id: 0,
          period_end_date: null,
          period_name: null,
          period_start_date: null,
          prefix: null,
          quater_number: 1,
          period_number: 1,
          year: null,
          erp_period_name: null
        }
      ],
      calendarDetailFields: [
        {
          key: 'prefix'
        },
        {
          key: 'year'
        },
        {
          key: 'period_name'
        },
        {
          key: 'erp_period_name',
          label: 'ERP Period Name'
        },
        {
          key: 'start_date_end_date',
          label: 'Start Date - End Date'
        },

        {
          key: 'quater_number',
          label: 'Quater No.',
          class: 'text-center'
        },
        {
          key: 'period_number',
          label: 'Period No.',
          class: 'text-center'
        },
        {
          key: 'adjustment_period_flag',
          label: 'ADJ Flag'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      legalEntityList: [],
      selectedLegalEntity: {
        value: null,
        text: null
      }
    };
  },
  mounted() {
    this.getCalendarPeriodDetail();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditCalendarPeriod();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    addEditCalendarPeriod() {
      const calendarDetails = this.calendarDetailList.map(data => {
        return {
          adjustment_period_flag: data.adjustment_period_flag,
          calendar_line_id: data.calendar_line_id,
          period_end_date: commonHelper.formattedDate(
            data.start_date_end_date[1]
          ),
          period_start_date: commonHelper.formattedDate(
            data.start_date_end_date[0]
          ),
          period_name: data.period_name
            ? format(new Date(data.period_name), 'MMM-yyyy')
            : null,
          // period_name: this.calendarDetailList[this.validateIndex].prefix + ' - ' + this.calendarDetailList[this.validateIndex].year,
          prefix: data.prefix,
          quater_number: data.quater_number,
          period_number: data.period_number,
          year: data.year ? format(new Date(data.year), 'yyyy') : null,
          erp_period_name: data.erp_period_name
        };
      });
      const payload = {
        calendar_dtl_id: this.calendarForm.calendar_dtl_id,
        calendar_line_details: calendarDetails
      };
      this.loader = true;
      this.$store
        .dispatch('calendar/addEditCalendarPeriod', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
            this.getCalendarPeriodDetail();
            this.$emit('updatePeriodCount', response.data.data.calendar_dtl_id);
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCalendarPeriodDetail() {
      if (this.calHdrId) {
        this.loader = true;
        this.$store
          .dispatch('calendar/getCalendarPeriodDetails', this.calHdrId)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.editMode = false;
              const respData = response.data.data;
              this.calendarForm = respData;
              this.calendarForm.start_end_date = [
                new Date(respData.start_date),
                new Date(respData.end_date)
              ];
              const filterCalData = respData.calendar_line_dtls.filter(
                data => data.calendar_line_id !== null
              );
              // const getCalDataArr = filterCalData.map(detail => {
              //   detail.period_name = detail.period_name
              //     ? new Date(detail.period_name)
              //     : null;
              //   // detail.year = detail.year;
              //   // ? new Date(detail.year) : null;
              //   return detail;
              // });
              if (filterCalData.length > 0) {
                this.calendarDetailList = filterCalData.map(data => {
                  data.start_date_end_date = [
                    new Date(data.period_start_date),
                    new Date(data.period_end_date)
                  ];
                  return data;
                });
              }
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    addNewRow(count) {
      if (this.editMode) {
        const calLength = this.calendarDetailList.length;
        let quaterVal = 1;
        if (calLength < 4) {
          quaterVal = 1;
        } else if (calLength > 3 && calLength < 8) {
          quaterVal = 2;
        } else if (calLength > 7 && calLength < 12) {
          quaterVal = 3;
        } else if (calLength > 11) {
          quaterVal = 4;
        }
        this.calendarDetailList.push({
          adjustment_period_flag: false,
          calendar_line_id: 0,
          period_end_date: null,
          period_name: null,
          period_start_date: null,
          prefix: null,
          quater_number: quaterVal,
          period_number: count,
          year: null,
          erp_period_name: null
        });
      }
    },
    validate(index) {
      this.validateIndex = index;
      this.calendarDetailList[this.validateIndex].period_name = this.calendarDetailList[this.validateIndex].prefix + ' - ' + this.calendarDetailList[this.validateIndex].year;
    },
    disabledDates(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    removeRow(index) {
      this.calendarDetailList.splice(index, 1);
    },
    periodDetails() {},
    rowSelected() {}
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
