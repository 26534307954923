import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import AddEditPeriod from './addEditPeriod';
export default {
  name: 'addEditCalendar',
  props: ['calendarDetailId'],
  components: { DatePicker, AddEditPeriod },
  data() {
    return {
      calendarIndex: null,
      calHdrId: null,
      calDtlId: null,
      showAddCalender: false,
      parent_value_set_id: null,
      vsetCode: null,
      showValueSetModal: null,
      unsubscribe: null,
      appCritListIndex: null,
      editMode: true,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      calendarForm: {
        calendar_hdr_id: 0,
        calendar_name: null,
        description: null
      },
      calendarDetailList: [
        {
          end_date: null,
          start_date: null,
          financial_year_display: null,
          period_count: null,
          calendar_dtl_id: 0
        }
      ],
      calendarDetailFields: [
        {
          key: 'financial_year_display',
          label: 'FY Display'
        },
        {
          key: 'start_date_end_date',
          label: 'Start Date - End Date'
        },
        {
          key: 'period_count',
          class: 'text-center'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      legalEntityList: [],
      selectedLegalEntity: {
        value: null,
        text: null
      }
    };
  },
  validations: {
    calendarForm: {
      calendar_name: {
        required
      },
      description: { required }
    }
  },
  mounted() {
    this.getCalendarDetail();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode && !this.showAddCalender) {
          this.addEditCalendar();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.calendarForm.calendar_hdr_id});
        }

      }
    });
  },
  methods: {
    addEditCalendar() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const calendarDetails = this.calendarDetailList.map(data => {
          return {
            calendar_dtl_id: data.calendar_dtl_id,
            end_date: commonHelper.formattedDate(data.start_date_end_date[1]),
            financial_year_display: data.financial_year_display,
            start_date: commonHelper.formattedDate(data.start_date_end_date[0])
          };
        });
        const payload = {
          calendar_hdr_id: this.calendarForm.calendar_hdr_id,
          calendar_name: this.calendarForm.calendar_name,
          description: this.calendarForm.description,
          calendar_details: calendarDetails
        };
        this.loader = true;
        this.$store
          .dispatch('calendar/addEditCalendar', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.$emit('updateList');
              this.calendarDetailId = response.data.data.calendar_hdr_id;
              this.getCalendarDetail();
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getCalendarDetail() {
      if (this.calendarDetailId) {
        this.loader = true;
        this.$store
          .dispatch('calendar/getCalendarDetail', this.calendarDetailId)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.editMode = false;
              const respData = response.data.data;
              this.calendarForm = {
                calendar_hdr_id: respData.calendar_hdr_id,
                calendar_name: respData.calendar_name,
                description: respData.description
              };
              if (
                respData.calendar_details &&
                respData.calendar_details.length > 0
              ) {
                const getCalDataArr = respData.calendar_details.map(detail => {
                  detail.start_date_end_date = [
                    new Date(detail.start_date),
                    new Date(detail.end_date)
                  ];
                  return detail;
                });
                this.calendarDetailList = getCalDataArr;
              }
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    addNewRow() {
      if (this.editMode) {
        this.calendarDetailList.push({
          end_date: null,
          start_date: null,
          start_date_end_date: null,
          financial_year_display: null,
          period_count: null,
          calendar_dtl_id: 0
        });
      }
    },
    removeRow(index) {
      this.calendarDetailList.splice(index, 1);
    },
    periodDetails(index, calHdrId) {
      this.calendarIndex = index;
      this.calHdrId = calHdrId;
      this.showAddCalender = true;
    },
    updatePeriodCount(calDtlId){
      this.calDtlId = calDtlId;
      this.getCalendarDetail(this.calDtlId)
    },
    rowSelected() {},
    hideModal() {
      this.showAddCalender = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
