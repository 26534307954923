import commonHelper from '@/app/utility/common.helper.utility';
import AddEditCalendar from './addEditCalendar';
export default {
  name: 'calendar',
  components: {
    AddEditCalendar
  },
  watch: {
    currentPage: function() {
      this.getCalendarList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getCalendarList();
    }
  },
  data() {
    return {
      calendarDetailId: null,
      unsubscribe: null,
      showAddCalender: false,
      loader: false,
      payload: {},
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      valueSetName: null,
      currentPage: 1,
      totalRows: null,
      calendar: {
        name: null,
        description: null
      },
      calendarList: [],
      calendarField: [
        {
          key: 'calendar_name',
          label: 'Name'
        },
        {
          key: 'description'
        }
      ]
    };
  },
  mounted() {
    this.getCalendarList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.calendarDetailId = null;
          this.showAddCalender = true;
        }
        if (actionName === 'download' && !this.showAddCalender) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = {...this.payload};
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'calendar/getCalendarList',
            'calendar',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getCalendarList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        calendar_name: this.calendar.name,
        description: this.calendar.description
      };
      this.loader = true;
      this.$store
        .dispatch('calendar/getCalendarList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.calendarList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clear() {
      this.calendar.name = null;
      this.calendar.description = null;
      this.getCalendarList();
    },
    rowSelected(item) {
      this.calendarDetailId = item.calendar_hdr_id;
      this.showAddCalender = true;
    },
    hideModal() {
      this.showAddCalender = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
